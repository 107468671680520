import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import cn from 'classnames';

import Validation from 'common/server/lib/validation';
import { content, htmlContent } from 'common/language/language';
import FadeInOut from './HOCs/FadeInOut';

import { CommentTab, OriginTab, NameTab, ErrorTab } from './submission-tabs/index';

import ArtworkThumbnail from './ArtworkThumbnail';
import Loader from './Loader';
import Icon from './Icon';

// clear data on close
export default class ArtworkHangForm extends Component {
  constructor(props) {
    super(props);
    this.Valid = null;
    this.tabs = ['name', 'origin', 'comment', 'error'];

    this.state = {
      currentTab: this.tabs[0],
      userComment: '',
      // userComment: 'Its so incredibly realistic, what an artist! She looks so.. forlorn. It breaks my heart and it reminds me of issues of colonialism in NZ.',
      userCommentErrors: [],
      userOrigin: '',
      // userOrigin: 'Wgton',
      userOriginErrors: [],
      userName: '',
      // userName: ['Pat','Rich','Paul','Mike','Dan','Tom','Carlos'][Math.floor(Math.random() * 7)],
      userNameErrors: [],

      userLatitude: null,
      userLongitude: null,

      submissionError: null,
      sending: false,
    };

    this.updateComment = this.updateComment.bind(this);
    this.updateOrigin = this.updateOrigin.bind(this);
    this.updateName = this.updateName.bind(this);

    this.nextIfValid = this.nextIfValid.bind(this);
    this.navigateNext = this.navigateNext.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);

    this.sendToArtWall = this.sendToArtWall.bind(this);
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
    this.afterSuccessfulPost = this.afterSuccessfulPost.bind(this);
    this.onSubmitFailure = this.onSubmitFailure.bind(this);

    this.clearState = this.clearState.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.successfulExit = this.successfulExit.bind(this);
    this.failExit = this.failExit.bind(this);

    this.trackFlow = this.trackFlow.bind(this);
    this.returnToStart = this.returnToStart.bind(this);
  }

  UNSAFE_componentWillMount() {
    const contentFunc = (type, field, ...args) => (
      content(`validations.${type}.${field}`, ...args)
    );
    this.Valid = new Validation(window.dejunk, window.rudeboy, contentFunc);
  }

  sendToArtWall() {
    const {
      userComment, userOrigin, userName, userLatitude, userLongitude,
    } = this.state;
    const { server, item, userCanPost } = this.props;

    if (!userCanPost()) {
      return this.onSubmitFailure('posting disabled');
    }

    this.setState({ sending: true }, () => {
      server.addPost({
        timeStamp: Date.now(),
        itemId: item.artworkId,
        userName: userName.trim(),
        userOrigin: userOrigin.trim(),
        userComment: userComment.trim(),
        userLatitude,
        userLongitude,
      })
        .then(this.onSubmitSuccess)
        .catch(this.onSubmitFailure);
    });
  }

  onSubmitFailure(error) {
    const {
      userName, userOrigin, userComment, userLatitude, userLongitude,
    } = this.state;
    const label = JSON.stringify({
      itemId: this.props.item.artworkId,
      userName,
      userOrigin,
      userComment,
      userLatitude,
      userLongitude,
    });

    this.props.analytics.event({
      category: 'Artwork Post Failure',
      action: String(error),
      label,
    });

    this.setState({
      sending: false,
      currentTab: 'error',
      submissionError: true,
    });
  }

  onSubmitSuccess(post) {
    const { setSubmittedPost } = this.props;
    setSubmittedPost(post, this.afterSuccessfulPost);
  }

  afterSuccessfulPost() {
    const { analytics, item, config } = this.props;

    analytics.event({
      category: 'Artwork Posted',
      action: `${item.artworkId} | ${item.title}`,
      label: config.artworkUrl(item.artworkId),
    });

    item.tags.forEach(it => {
      analytics.event({
        category: 'Artwork Subject',
        action: 'Post Artwork',
        label: `${it}`,
      });
    });

    analytics.event({
      category: 'Artwork Submission Flow',
      action: 'Step: Complete',
    });

    this.clearState(this.successfulExit);
  }

  clearState(cb = () => null) {
    this.setState({
      sending: false,
      userOrigin: '',
      userComment: '',
      userLatitude: null,
      userLongitude: null,
      submissionError: null,
      currentTab: this.tabs[0],
    }, cb);
  }

  successfulExit() {
    this.props.closeForm();
    this.props.closeSelection();
    this.props.showPostMsg();
  }

  failExit() {
    this.clearState(() => {
      this.props.closeForm();
      this.props.closeSelection();
    });
  }

  closeModal() {
    this.props.analytics.event({
      category: 'Submission Abandonment',
      action: `Step: ${this.state.currentTab}`,
    });

    this.clearState(this.props.closeForm());
  }

  updateComment(e) {
    e.preventDefault();

    // eg long comment Note that accordingly to the WHATWG forms spec
    // selectionStart, selectionEnd properties and setSelectionRange method apply on
    const el = e.target;
    const comment = el.value;

    // const errors = this.Valid.userComment(comment)

    // there is an issue with react not being able to dermin where the cursor should go if you are
    // preventing rerender and your cursor is not at the end. It forces the end which is
    // really annoying so just displaying negatives now

    // if (comment.length <= maxCommentLength) {
    this.setState({
      userComment: comment,
      // userCommentErrors: errors
    });
    // }
  }

  updateOrigin(e) {
    e.preventDefault();

    const el = e.target;
    const origin = el.value;
    // const errors = this.Valid.userOrigin(origin);

    this.setState({
      userOrigin: origin,
      // userOriginErrors: errors
    });
  }

  updateName(e) {
    e.preventDefault();
    const el = e.target;
    const name = el.value;
    // const errors = this.Valid.userName(name);
    this.setState({
      userName: name,
      // userNameErrors: errors
    });
  }

  errorMsgForAnalytics = msg => {
    if (msg.indexOf('offensive') > -1) { return 'offensive'; }
    if (msg.indexOf('spelling') > -1) { return 'invalid'; }
    if (msg.indexOf('short') > -1) { return 'length'; }
    // else if( msg.indexOf('required') > -1 ) { return 'required' }
    if (msg.indexOf('Please enter') > -1) { return 'required'; }
    if (msg.indexOf('not allowed') > -1) { return 'special-chars'; }
    return 'unknown';
  };

  trackValidationError = (value, errors, currentTab) => {
    const tabName = currentTab[0].toUpperCase() + currentTab.slice(1);
    const errorString = errors.map(this.errorMsgForAnalytics).join(', ');

    this.props.analytics.event({
      category: 'Validation Error',
      action: `${tabName} - [${errorString}]`,
      label: value,
    });
  }

  nextIfValid() {
    const { currentTab, userComment, userName, userOrigin } = this.state;

    let value;
    let errors = [];

    if (currentTab === 'comment') {
      if (userComment.length > 0) {
        value = userComment;
        errors = this.Valid.userComment(userComment);
        this.setState({ userCommentErrors: errors });
      }
    } else if (currentTab === 'name') {
      value = userName;
      errors = this.Valid.userName(userName);
      this.setState({ userNameErrors: errors });
    } else if (currentTab === 'origin') {
      value = userOrigin;
      errors = this.Valid.userOrigin(userOrigin);
      this.setState({ userOriginErrors: errors });
    }

    if (errors.length === 0) {
      this.navigateNext();
      return;
    }

    this.trackValidationError(value, errors, currentTab);
  }

  sendToArtWallIfValid = () => {
    const { currentTab, userComment } = this.state;

    let value;
    let errors = [];

    if (currentTab === 'comment') {
      if (userComment.length > 0) {
        value = userComment;
        errors = this.Valid.userComment(userComment);
        this.setState({ userCommentErrors: errors });
      }
    }

    if (errors.length === 0) {
      this.sendToArtWall();
    }

    this.trackValidationError(value, errors, currentTab);
  }

  trackFlow() {
    const tab = this.state.currentTab;
    const skipped = {
      comment: !this.state.userComment,
      name: !this.state.userName,
      origin: !this.state.userOrigin,
    };

    this.props.analytics.event({
      category: 'Artwork Submission Flow',
      action: `Step: ${tab[0].toUpperCase() + tab.slice(1)}`,
      label: skipped[tab] ? 'Skipped' : '-',
    });
  }

  navigateNext() {
    this.trackFlow();
    const nextIdx = this.tabAt(this.state.currentTab) + 1;
    const nextTab = this.tabs[nextIdx];
    if (typeof nextTab !== 'undefined') {
      this.setState({ currentTab: nextTab });
    }
  }

  navigateBack() {
    const prevIdx = this.tabAt(this.state.currentTab) - 1;
    const prevTab = this.tabs[prevIdx];
    if (typeof prevTab !== 'undefined') {
      this.setState({ currentTab: prevTab });
    } else {
      this.closeModal();
    }
  }

  returnToStart() {
    this.goToTab(this.tabs[0]);
    this.props.analytics.event({
      category: 'Artwork Submission Flow',
      action: 'Step: Confirmation (back to start)',
    });
  }

  goToTab(tabname) {
    this.setState({ currentTab: tabname });
  }

  tabAt(tabname) {
    return this.tabs.indexOf(tabname);
  }

  onTabSelect(idx) {
    if (this.tabs[idx] === 'error' && this.state.submissionError !== true) {
      return;
    }

    this.setState({ currentTab: this.tabs[idx] });
  }

  renderModal() {
    const { item, config: { img: { sm } } } = this.props;
    const {
      userName, userOrigin, userComment, currentTab,
      userCommentErrors, userNameErrors, userOriginErrors,
    } = this.state;

    // Toggle this line to force loading screen (when a modal window is open)
    // return (<Loader text={content('byod.form.sending')} />);

    return (
      <div className={cn('hang-artwork-modal', `hang-artwork-modal--${currentTab}`)} key="hang-artwork-modal">
        <FadeInOut>
          { this.state.sending && <Loader text={content('byod.form.sending')} /> }
        </FadeInOut>

        <Tabs selectedIndex={this.tabAt(currentTab)} onSelect={this.onTabSelect}>
          <div className="modal__controls">
            <div className="modal__actions hangform-header">
              <a className="modal__back" onClick={this.navigateBack}><Icon icon="larr" /> { content('labels.back') }</a>
              <TabList>
                { this.tabs.map((tab, i) => <Tab key={i} className={`react-tabs__tab tab-${tab}`} />) }
              </TabList>
              <a className="modal__close" onClick={this.closeModal}>{ content('labels.close') } <Icon icon="x" /></a>
            </div>

            <div className="flex mb-4">
              <ArtworkThumbnail item={item} func={sm} />
              <div className="flex flex-jc-end flex-column ml-4">
                <p>
                  <span className="artist">
                    { item.artist }
                  </span>
                  <br />
                  <span className="title italic">
                    { item.title }
                  </span>
                  <span className="date ml-2">
                    { item.date }
                  </span>
                  
                  
                </p>
              </div>
            </div>
            <div className="modal__user">
              { userName ? (
                userOrigin ? (
                  htmlContent('p', 'byod.form.confirm.name-and-origin', userName, userOrigin)
                ) : (
                  htmlContent('p', 'byod.form.confirm.name-only', userName)
                )
              ) : (
                htmlContent('p', 'byod.form.confirm.name-only', '<strong>You</strong>')
              )}
            </div>
          </div>

          <TabPanel>
            <NameTab
              name={userName}
              change={this.updateName}
              errors={userNameErrors}
              next={this.nextIfValid}
              Input={this.props.config.inputs.Input}
            />
          </TabPanel>

          <TabPanel>
            <OriginTab
              origin={userOrigin}
              change={this.updateOrigin}
              username={userName}
              skip={() => this.setState({ userOrigin: '', userOriginErrors: [] }, this.navigateNext)}
              errors={userOriginErrors}
              next={this.nextIfValid}
              Input={this.props.config.inputs.Input}
              focusOnMount={this.props.config.autoFocusFields}
            />
          </TabPanel>

          <TabPanel>
            <CommentTab
              comment={userComment}
              change={this.updateComment}
              skip={() => this.setState({ userComment: '', userCommentErrors: [] }, this.navigateNext)}
              errors={userCommentErrors}
              conf={this.props.config}
              next={this.sendToArtWallIfValid}
              userCanPost={this.props.userCanPost}
              TextArea={this.props.config.inputs.TextArea}
              focusOnMount={this.props.config.autoFocusFields}
            />
          </TabPanel>

          <TabPanel>
            <ErrorTab
              back={this.goToTab.bind(this, 'comment')}
              close={this.failExit}
            />
          </TabPanel>

        </Tabs>

      </div>
    );
  }

  render() {
    return (
      <FadeInOut>
        { this.props.active ? this.renderModal() : null }
      </FadeInOut>
    );
  }
}
