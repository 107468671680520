import React, { Component } from 'react';
import cn from 'classnames';

import { setLang } from 'common/language/language';
import Offline from 'set/components/Offline';
import ArtworkModal from './ArtworkModal';
import ArtworkSet from './ArtworkSet';

import PostMessage from './PostMessage';
import Header from './Header';
import FadeInOut from './HOCs/FadeInOut';

import isMobileSafari from '../utils/isMobileSafari';

export default class Artwall extends Component {
  constructor(props) {
    super(props);

    // remove unused config
    const { firebase, ...config } = props.config;

    this.config = config;

    // setLang(this.config.defaultLang);
    setLang('en');

    this.state = {
      activeItem: null,

      // the post data of the most recent post a user has submitted
      submittedPost: null,

      showPostMsg: false, // display a message?
      postInQueue: null, // is it in the queue
      postQueuePosition: null, // queue position
      timeUntilDisplay: null, // position / rotation time
      queue: [],
      currentPost: null,

      notifySubmissionOnWall: false,
      // submissionOnWallId is used to compare against the current queue post
      submissionOnWallId: null,
      lastServerQueuePop: Date.now(),
      // now is a approximate since we cant be sure until we receive a signal,
      lastPosted: localStorage.getItem('last_posted') || null,
      initialFBConnection: false,
      networkConnected: navigator.onLine,
      allowPosting: null,
      loading: true,
    };

    this.toggleActiveItem = this.toggleActiveItem.bind(this);

    // Firebase DB event listener callbacks
    this.updateQueue = this.updateQueue.bind(this);
    this.updateCurrentItem = this.updateCurrentItem.bind(this);

    this.showPostMsg = this.showPostMsg.bind(this);
    this.closePostMsg = this.closePostMsg.bind(this);
    this.setSubmittedPost = this.setSubmittedPost.bind(this);
    this.clearLastPosted = this.clearLastPosted.bind(this);
    this.clearSubmittedPost = this.clearSubmittedPost.bind(this);
    this.clearPostOnWallNotification = this.clearPostOnWallNotification.bind(this);

    this.startTick = this.startTick.bind(this);
    this.stopTick = this.stopTick.bind(this);
    this.updateTimeUntilDisplay = this.updateTimeUntilDisplay.bind(this);
    this.ticker = null;

    this.networkConnectionListener = this.networkConnectionListener.bind(this);
    this.userCanPost = this.userCanPost.bind(this);
    this.setToOffline = this.setToOffline.bind(this);
    this.setToOnline = this.setToOnline.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { server } = this.props;
    server.queue(this.updateQueue);
    server.currentPost(this.updateCurrentItem);
    server.watchHealth(status => {
      this.setState({ status });
    });
    server.watchConfig(data => {
      this.setState({
        'allowPosting': data.byod.allow_posting,
        'offlineMode': data.byod.offline,
        'offlineModeMessage': data.byod.offline_message,
        'loading': false,
      });
    });

    if (isMobileSafari()) {
      document.body.classList.add('mobile-safari');
    }
  }

  componentDidMount() {
    this.props.server.watchConnection(this.networkConnectionListener);
    window.addEventListener('offline', this.setToOffline);
    window.addEventListener('online', this.setToOnline);
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.setToOffline);
    window.removeEventListener('online', this.setToOnline);
  }

  // this is the firebase listener. We have our own listener below.
  networkConnectionListener(connected) {
    const { initialFBConnection, networkConnected } = this.state;

    this.setState({
      // this should only change first after load
      initialFBConnection: initialFBConnection === false ? connected : initialFBConnection,
      networkConnected: initialFBConnection ? connected : networkConnected,
    });
  }

  setToOffline() {
    if (this.state.networkConnected === true) {
      this.setState({ networkConnected: false });
    }
  }

  setToOnline() {
    if (this.state.networkConnected === false) {
      this.setState({ networkConnected: true });
    }
  }

  toggleBodyScrollClass(scrollable) {
    if (!scrollable) {
      this.savedScroll = [window.scrollX, window.scrollY];
      document.documentElement.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
      window.scrollTo(this.savedScroll[0], this.savedScroll[1]);
    }
  }

  toggleActiveItem(item = null) {
    const bodyScrollable = !item; // null => no modal so scrollable = true
    this.toggleBodyScrollClass(bodyScrollable);
    this.setState({ activeItem: item });
  }

  updateQueue(queue) {
    const nextQueue = queue === null ? [] : Object.keys(queue).map(it => queue[it].postId);

    const { submittedPost } = this.state;

    // this declared here and is mutated conditionally below

    const nextState = { queue: nextQueue };

    if (submittedPost) {
      const pos = nextQueue.indexOf(submittedPost.postId);

      if (pos > -1) {
        const postInQueue = true;
        const postQueuePosition = pos + 1; // +1 array zero base

        nextState.postInQueue = postInQueue;
        nextState.postQueuePosition = postQueuePosition;
      } else { // post is not in queue
        nextState.postInQueue = null;
        nextState.postQueuePosition = null;
        nextState.timeUntilDisplay = null;
      }
    }

    this.setState(nextState, this.clearSubmittedPost); // if post is not in queue remove it
  }

  updateCurrentItem(current) {
    const nextState = {};
    let cb = null;
    nextState.lastServerQueuePop = Date.now();

    if (this.state.submissionOnWallId === current.postId) {
      nextState.notifySubmissionOnWall = true;
      nextState.submissionOnWallId = null;
      cb = this.closePostMsg;
    }
    this.setState(nextState, cb);
  }

  updateTimeUntilDisplay() {
    const { timeUntilDisplay } = this.state;

    if (timeUntilDisplay !== null) {
      this.setState({ timeUntilDisplay: timeUntilDisplay - 1 });
    }
  }

  startTick() {
    clearInterval(this.ticker);
    this.ticker = setInterval(this.updateTimeUntilDisplay, 1000);
  }

  stopTick() {
    clearInterval(this.ticker);
    this.ticker = null;
  }

  // this is called from post form after a post has been validated on the server
  setSubmittedPost(post, cb) {
    const lastPosted = Date.now();
    localStorage.setItem('last_posted', lastPosted);

    const { queue, lastServerQueuePop } = this.state;

    const nextState = {
      submittedPost: post,
      submissionOnWallId: post.postId,
      notifySubmissionOnWall: false, // remove any banner from a previous post.
      lastPosted,
    };

    if (post.queue_dest === 'queue') {
      const elapsedSecondsInCurrentTick = (Date.now() - lastServerQueuePop) / 1000;

      // check if its in the queue already - sometimes queue signal
      // is received before the post signal
      const pos = queue.indexOf(post.postId);
      const queuePos = pos > -1 ? (pos + 1) : (queue.length + 1);

      nextState.postInQueue = true;
      nextState.postQueuePosition = queuePos;
      nextState.timeUntilDisplay = (queuePos * 30) - Math.ceil(elapsedSecondsInCurrentTick);
    }

    this.props.analytics.event({
      category: 'Add To Queue',
      action: post.queue_dest,
      label: post.queue_dest === 'queue' ? `Position [${nextState.postQueuePosition}]` : '-',
    });

    this.startTick();
    setTimeout(this.clearLastPosted, 20000);
    this.setState(nextState, cb);
  }

  clearLastPosted() {
    localStorage.removeItem('last_posted');
    this.setState({ lastPosted: null });
  }

  clearSubmittedPost() {
    if (this.state.postInQueue) { return; }
    this.stopTick();
    this.setState({
      submittedPost: null,
    });
  }

  clearPostOnWallNotification() {
    this.setState({
      notifySubmissionOnWall: false,
    }, this.clearSubmittedPost);
  }

  showPostMsg() {
    this.toggleBodyScrollClass(false);
    this.setState({ showPostMsg: true });
  }

  closePostMsg() {
    this.toggleBodyScrollClass(true);
    this.setState({ showPostMsg: false });
  }

  userCanPost() {
    const { networkConnected, lastPosted, status, allowPosting } = this.state;
    const notTooSoon = !lastPosted ? true : Date.now() - lastPosted > 20000;
    return networkConnected && status === 'up' && notTooSoon && allowPosting;
  }

  render() {
    const {
      activeItem, submittedPost, showPostMsg, postInQueue, postQueuePosition,
      notifySubmissionOnWall: onWallNow, queue, timeUntilDisplay,
      networkConnected, status,
    } = this.state;

    const { server, analytics } = this.props;

    const offline = networkConnected === false;
    const notifySubmissionOnWall = this.config.showPostBanners && onWallNow;
    const upcomingPostBanner = (
      this.config.showPostBanners && (!showPostMsg && postInQueue && !notifySubmissionOnWall)
    );

    const artwallClasses = cn(
      'artwall',
      {
        'artwall--banner-1': upcomingPostBanner,
        'artwall--banner-2': notifySubmissionOnWall,
      },
    );

    if (this.state.loading) {
      return (
        <div className="flex h-100vh h-vh flex-ai-center flex-jc-center bg-pure-black">
          <p className="color-white opacity-30 uppercase text-xs tracking-4">loading</p>
        </div>
      );
    }

    if (this.state.offlineMode) {
      return (
        <Offline message={this.state.offlineModeMessage} />
      );
    }

    return (
      <div id={`app-ns-${this.config.appNameSpace}`} className="container">

        <Header
          upcomingPostBanner={upcomingPostBanner}
          notifySubmissionOnWall={notifySubmissionOnWall}
          clearPostOnWallNotification={this.clearPostOnWallNotification}
          timeUntilDisplay={timeUntilDisplay}
          offline={offline}
          status={status}
        />

        <div className={artwallClasses}>

          <ArtworkModal
            item={activeItem}
            deactivateFunc={this.toggleActiveItem}
            setSubmittedPost={this.setSubmittedPost}
            showPostMsg={this.showPostMsg}
            postInQueue={postInQueue}
            queueLength={queue ? queue.length : 0}
            server={server}
            analytics={analytics}
            config={this.config}
            userCanPost={this.userCanPost}
          />

          <ArtworkSet
            analytics={this.props.analytics}
            config={this.props.config}
            onSelect={this.toggleActiveItem}
            server={this.props.server}
          />

        </div>

        <FadeInOut>
          {/* { true && ( */}
          { showPostMsg && submittedPost !== null && !notifySubmissionOnWall && (
            <PostMessage
              post={submittedPost}
              postInQueue={postInQueue}
              position={postQueuePosition}
              timeToDisplay={timeUntilDisplay}
              close={this.closePostMsg}
              autoClose={this.config.autoClosePostMessage}
            />
          ) }
        </FadeInOut>

      </div>
    );
  }
}
