import React from 'react';
import theme from '../theme';
import { htmlContent } from 'common/language/language';

export default function Header(props) {

  return (
    <>
      <div className="header">
        <div className="expo-2020-brand grid max-w-xl mx-6 xl:mx-auto flex-ai-center">
          <div className="md:c4 lg:c3">
            <div className="expo-2020-brand-main">
              <img src={theme.logos.byod} className="logo" alt="Logo" width="338" height="338" />
              {/* { htmlContent('h1', 'byod.header.brand') } */}
            </div>
          </div>

          <div className="md:c8 lg:c7 lg:o1">
            <p className="send-instruction font-semibold">
              {htmlContent('span', 'byod.header.mi-instruction')}
            </p>
            <p className="send-instruction">
              {htmlContent('span', 'byod.header.en-instruction')}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
