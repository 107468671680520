import styles from './sass/settings.scss';

const theme = {
  color: {
    primary: styles.colorAccentPrimary,
  },
  wallColumns: 3,
  wallVerbs: {
    singular: 'artwork',
    plural: 'artworks',
    wallName: 'Pātū Toi | Art Wall',
  },

  logos: {
    byod: '/static/assets/byod-logo.svg',
    wall: '/static/assets/wall-logo.svg',
  },
  sponsors: [
    {
      src: '/static/assets/tepapa-logo.png',
      name: 'Museum of New Zealand Te Papa Tongarewa',
    },
  ],
  sendLabel: 'Send to Art Wall',
  introText: {
    mi: 'Whakaraparapatia he taha o te kohinga toi ā-motu ka tukua tō kōwhiringa ki te pātū.',
    en: 'Browse a slice of the National Art Collection and share your choice to the Art Wall.',
  },
  footerCopy: 'Ka pai – nice work! You’ve browsed <span class="nowrap">{}</span> artworks from the National Art Collection.',
};

export default theme;