import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import FirstChild from './FirstChild';

export default function FadeInOut(props) {
  return (
    <TransitionGroup component={FirstChild}>
      <CSSTransition
        classNames="modal"
        timeout={{ enter: 500, exit: 300 }}
      >
        <span>
          { props.children }
        </span>
      </CSSTransition>
    </TransitionGroup>
  );
}
