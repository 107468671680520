import React, { Component } from 'react';
import numberToWord from '../utils/numberToWord';
import timeToDisplay from '../utils/timeToDisplay';
import { content, htmlContent, rawContent } from 'common/language/language';

export default class PostMessage extends Component {
  constructor(props) {
    super(props);
    this.circle = null;
    this.animating = false;
    this.state = {
      animating: false,
      timeToAutoClose: 15,
    };
  }

  componentDidUpdate(prevProps) {
    if (typeof prevProps.timeToDisplay === 'number' && this.circle && this.state.animating === false) {
      this.circle.style.webkitAnimationDuration = `${prevProps.timeToDisplay}s`;
      this.circle.style.animationDuration = `${prevProps.timeToDisplay}s`;
      this.setState({ animating: true });
    }

    if (this.props.autoClose) {
      if (this.props.timeToDisplay === prevProps.timeToDisplay - 1) {
        if (this.state.timeToAutoClose <= 0) {
          this.props.close();
        }

        this.setState({ timeToAutoClose: this.state.timeToAutoClose - 1 });
      }
    }
  }

  render() {
    const { position, close, autoClose } = this.props;
    let { timeToDisplay: seconds, postInQueue } = this.props;
    let message;

    // overide these to force view messaging
    // seconds = 30;
    // postInQueue = true;

    const positionalWord = numberToWord(position, rawContent('byod.post.positions'));

    const positionalSentence = positionalWord
      ? content('byod.post.text-position-in-queue', positionalWord)
      : content('byod.post.text-position-fallback');

    if (postInQueue === true) {
      message = (
        <div className="message">
          <h4>{ content('byod.post.heading') }</h4>
          { htmlContent('byod.post.text', positionalSentence) }
          <h5 className="time-display-heading">{ content('byod.post.time-to-display') }</h5>
          <span className="time-display">
            { seconds > 0 ? timeToDisplay(seconds) : 'up next' }
            <svg>
              <circle r="85" cx="88" cy="89" ref={el => this.circle = el} />
            </svg>
          </span>
          { autoClose && seconds > this.state.timeToAutoClose && (
            <p className="auto-close-message">
              { content('byod.post.time-to-autoclose', this.state.timeToAutoClose) }
            </p>
          ) }
        </div>
      );
    } else {
      message = (
        <div className="message">
          <h4>{ htmlContent('byod.post.heading') }</h4>
          <p>{ htmlContent('byod.post.text-for-backlog') }</p>
        </div>
      );
    }

    return (
      <div className="messaging-modal">
        { message }
        <a className="messaging-modal__close" onClick={close}>
          { content('labels.close-window') }
        </a>
      </div>
    );
  }
}
