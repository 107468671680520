import React, { Component } from 'react';

import cn from 'classnames';

import imgRenderSize from '../utils/imgRenderSize';


export default class Artwork extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgLoaded: false,
      fallbackSrc: null,
    };
    this.image = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleImgLoad = this.handleImgLoad.bind(this);
    this.handleError = this.handleError.bind(this);
    this.resetImageSrc = this.resetImageSrc.bind(this);
  }

  componentDidMount() {
    if (this.image && this.image.complete) {
      this.handleImgLoad();
    }
    window.addEventListener('online', this.resetImageSrc);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.resetImageSrc);
  }

  handleImgLoad() {
    setTimeout(() => this.setState({ imgLoaded: true }), 500);
  }

  handleClick(e) {
    e.preventDefault();
    const {
      item, setActiveFunc, analytics, config,
    } = this.props;
    setActiveFunc(item);

    analytics.event({
      category: 'Artwork Opened',
      action: `${item.artworkId} | ${item.title}`,
      label: config.artworkUrl(item.artworkId),
    });

    item.tags.forEach((tag) => {
      analytics.event({
        category: 'Artwork Subject',
        action: 'View Artwork',
        label: `${tag}`,
      });
    });
  }

  handleError(e) {
    e.preventDefault();
    // if our fallback errors dont start an infinite loop
    if (this.state.fallbackSrc !== null) { return; }

    const { config, item } = this.props;

    this.image.setAttribute('data-original-src', this.image.src);
    this.image.src = config.img.fallback(item.artworkImgId);

    this.setState({
      fallbackSrc: config.img.fallback(item.artworkImgId),
      imgLoaded: true,
    });
  }

  resetImageSrc() {
    // make this non blocking cause it happens on all images at once
    if (this.state.fallbackSrc) {
      setTimeout(() => {
        const origSrc = this.image.getAttribute('data-original-src');
        if (origSrc && origSrc !== this.image.src) {
          this.image.src = origSrc;
          this.setState({ fallbackSrc: null });
        }
      });
    }
  }

  render() {
    const { colWidth, config } = this.props;
    const { fallbackSrc } = this.state;
    const {
      artworkImgId, artist, date, description, title, width: srcWidth, height: srcHeight, img, notice,
    } = this.props.item;
    const dimensions = imgRenderSize(srcWidth, srcHeight, colWidth);

    const classes = cn('artwork', {
      'artwork--initial-render': !this.state.imgLoaded,
      'artwork--after-render': this.state.imgLoaded,
      'artwork--img-failed': fallbackSrc !== null,
      'artwork--has-notice': !!notice,
    });

    const src = fallbackSrc !== null ? fallbackSrc
      : document.body.offsetWidth < 420 ? config.img.sm(artworkImgId)
        : config.img.md(artworkImgId);

    return (
      <div className={classes} onClick={this.handleClick}>
        <img
          src={src}
          alt={title}
          width={dimensions.width}
          height={dimensions.height}
          style={dimensions}
          ref={el => this.image = el}
          onLoad={this.handleImgLoad}
          onError={this.handleError}
        />
      </div>
    );
  }
}
