import React, { Component } from 'react';
import cn from 'classnames';
import get from 'lodash/get';

import shuffle from '../utils/array.shuffle';

import ArtworkGrid from './ArtworkGrid';
import { content, tokeniseStr } from 'common/language/language';


class ArtworkSetSelector extends Component {
  constructor(props) {
    super(props);

    // Default to setting the first set as active.
    const selectedSet = props.sets[0];

    this.state = {
      selected: selectedSet,
    };

    // Notify parent that we have activated the set.
    this.props.onChange(selectedSet);
  }

  onChange(item) {
    this.setState({ selected: item });
    this.props.onChange(item);
  }

  render() {
    const { sets } = this.props;

    if (sets.length <= 1) {
      return null;
    }

    return (
      <div className="artwork-sets-selector">
        {sets.map((item) => {
          const className = cn(
            'artwork-sets-option',
            { selected: item === this.state.selected },
          );

          return (
            <span
              key={item}
              className={className}
              onClick={() => { this.onChange(item); }}
            >
              {item}
            </span>
          );
        })}
      </div>
    );
  }
}

export default class ArtworkSet extends Component {
  constructor(props) {
    super(props);

    this.config = this.props.config;

    this.state = {
      art: {},
      sets: {},
      items: [],
      setsLoaded: false,
      artLoaded: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchDataSet('art').then((data) => { this.initArt(data); });
    this.fetchDataSet('sets').then((data) => { this.initSets(data); });
  }

  // todo. this needs rework. There was a half implemented attempt here at
  // graceful fallback for offline for kiosk. it was a mess and confusing and needed rework..
  // I've simplified but kiosk/cors/file:// mode has been removed.
  fetchDataSet(name, retry = true) {
    const url = `/static/data/${name}.json`;

    return new Promise((resolve, reject) => {
      fetch(url)
        .then(resp => resp.json())
        .then(json => resolve(json))
        .catch((err) => {
          this.props.analytics.exception(err, true);
          if (retry) {
            setTimeout(() => {
              this.fetchDataSet(name, false)
                .then(data => resolve(data))
                .catch(err2 => reject(err2));
            }, 1000);
          }
        });
    });
  }

  // call back when data is loaded. initialises items on state.
  initArt(art) {

    // dev - to open a random artwork
    // const artKeys = Object.keys(art);
    // const randItem = art[artKeys[Math.floor(Math.random() * artKeys.length)]]
    // this.props.onSelect(randItem);

    this.setState({ art, artLoaded: true });
  }

  initSets(sets) {
    const { shuffleOnLoad } = this.props.config;
    const allSets = Object.keys(sets);

    if (shuffleOnLoad) {
      allSets.forEach(set => shuffle(sets[set].items));
    }

    this.setState({ sets, setsLoaded: true });
  }

  setChanged(name) {
    const { sets } = this.state;
    window.scrollTo(0, 0);

    this.setState({
      activeSet: name,
      activeSetDescription: get(sets, `[${name}].description`, 'Set not found. {} items.'),
      items: get(sets, `[${name}].items`, []),
    });
  }

  render() {
    const { analytics, config, onSelect } = this.props;
    const { art, sets, items, activeSet, activeSetDescription, setsLoaded, artLoaded } = this.state;
    const setNames = Object.keys(sets);

    const loaded = setsLoaded && artLoaded;

    if (loaded === false) {
      return (
        <h5 className="text-center mt-4">
          {content('labels.loading')}
        </h5>
      );
    }

    return (
      <div>
        <ArtworkSetSelector
          sets={setNames}
          onChange={(name) => { this.setChanged(name); }}
        />

        {activeSetDescription && (
          <p
            dangerouslySetInnerHTML={{
              __html: tokeniseStr(activeSetDescription, items.length),
            }}
          />
        )}

        <ArtworkGrid
          items={items}
          art={art}
          activeSet={activeSet}
          analytics={analytics}
          config={config}
          onSelect={onSelect}
          setCount={setNames.length}
        />
      </div>
    );
  }
}
