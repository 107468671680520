import React, { Component } from 'react';
import { content, htmlContent } from 'common/language/language';
import Icon from '../Icon';

export default class NameTab extends Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.focusInput();
  }

  handleNext() {
    if (this.props.next() === false) {
      this.focusInput();
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleNext();
    }
  }

  focusInput() {
    if (this.Input && this.Input.input) {
      this.Input.input.focus();
    }
  }

  render() {
    const { errors, name, change, Input } = this.props;

    const hasErrors = errors.length > 0;
    return (
      <div className={`name-tab ${hasErrors ? 'contains-errors' : ''}`}>
        <div className="tab-content">
          <label htmlFor="username-input">
            { htmlContent('span', 'byod.form.name.label') }<br />
          </label>

          { hasErrors && (
            <div className="formfield__errors">
              { errors.map((err, i) => <p key={`name-error-${i}`}>{err}</p>) }
            </div>
          ) }

          <div className="input-button-lockup">
            <Input
              id="username-input"
              placeholder={content('byod.form.name.example')}
              spellCheck={false} // y u no work?
              className="hangform__formfield"
              value={name}
              onChange={change}
              onKeyPress={this.handleKeyPress} // only on byod
              next={this.handleNext} // for keyboard on kiosk
              ref={el => this.Input = el}
              autoComplete="name"
            />
            <a className="modal__send btn btn--naked btn--medium" onClick={this.handleNext}>
              { content('labels.next') } <Icon icon="rarr" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
