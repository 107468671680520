const validationConfig = require('common/server/validation.config');

const imgPrefix = typeof window !== 'undefined' ? (window.location.protocol === 'file:' ? '' : '/') : '/';

// todo: almost all of this is not used on the server. need to have a web config and server config.
const FIREBASE_ENV = __FIREBASE_ENV;

// const defaultCredentials = require('./credentials.js');

const defaultCredentials = {default:{
  projectId: 'art-wall-nc',
  apiKey: 'AIzaSyCAdlQ5s_uhN1FDHXjsIqEm7OAWnF0SC3k',
  databaseURL: 'https://art-wall-nc-default-rtdb.asia-southeast1.firebasedatabase.app',
  publicUrl: 'https://art-wall.tepapa.govt.nz',
  storageBucket: 'gs://art-wall-nc.appspot.com',
  analyticsKey: 'G-07STQYFGPM',
}};
const credentials = defaultCredentials.default;

const appUrl = credentials.publicUrl;

const { analyticsKey } = credentials;

const sharedConfig = {
  // used in kiosk and byod
  img: {
    static: fileName => `${imgPrefix}static/images/${fileName}`,
    sm: id => `${imgPrefix}static/images/art/200/${id}.jpg`,
    md: id => `${imgPrefix}static/images/art/400/${id}.jpg`,
    lg: id => `${imgPrefix}static/images/art/800/${id}.jpg`,
    xl: id => `${imgPrefix}static/images/art/1000/${id}.jpg`,
    // fallback: (id) => `${imgPrefix}static/images/art/800/${id}.jpg`,
    // fallback: (id) => `http://collections.tepapa.govt.nz/APICollection/media/${id}/500`,
    fallback: id => `${imgPrefix}static/images/shim.png`,
  },

  // used in analytics
  artworkUrl: artworkId => `https://collections.tepapa.govt.nz/object/${artworkId}`,
};

// note: window.FIREBASE_ENV is set in the build task. it sets a global property
// on the window object which tells our apps which firebase to use. This value
// must be set anywhere this file is used.

module.exports = {
  firebase: credentials,

  // artDataUrl must allow cors access.
  dataUrl: name => `${appUrl}/static/data/${name}.json`,
  artDataUrl: `${appUrl}/static/data/art.json`,

  analyticsKey,

  ...sharedConfig,
  ...validationConfig,

  wall: {
    width: 1920,
    height: 1200,
  },
};
