import debounce from './debounce';

export default function onResize(callback) {
  const fn = debounce(callback, 100);
  window.addEventListener('resize', fn);

  // return object with off function to remove resize handler in consumer
  return {
    off: () => window.removeEventListener('resize', fn),
  };
}
