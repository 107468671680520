let loaded;
let debug;
let gaId;
let appName;

const log = (...args) => {
  if (debug && console && console.log) {
    console.log(...args);
  }
};

function initExeptionLogging(opts) {
  window.onerror = function customErrorHandler(errorMessage, url, lineNumber, columnNumber, errorObject) {
    if (typeof ga === 'function') {
      let exceptionDescription = errorMessage;
      if (typeof errorObject !== 'undefined' && typeof errorObject.message !== 'undefined') {
        exceptionDescription = errorObject.message;
      }

      exceptionDescription += ` @ ${url}:${lineNumber}:${columnNumber}`;

      log('[EXCEPTION]', exceptionDescription);

      ga('send', 'exception', {
        exDescription: exceptionDescription,
        exFatal: false, // Some Error types might be considered as fatal.
        appName: opts.appName,
        appVersion: '0.1.0',
      });
    }

    // If the previous "window.onerror" callback can be called, pass it the data:
    if (typeof originalWindowErrorCallback === 'function') {
      return originalWindowErrorCallback(errorMessage, url, lineNumber, columnNumber, errorObject);
    }
    // Otherwise, Let the default handler run:
    return false;
  };
}

const analytics = {
  init(opts) {
    debug = opts.debug;
    appName = opts.appName;
    if (loaded) { return; }
    if (typeof ga !== 'function') { log('Google analytics not loaded.'); return false; }
    if (!opts.gaId) { log('Google analytics id not supplied.'); return false; }

    const GA_LOCAL_STORAGE_KEY = 'ga:clientId';

    try {
      if (window.location.protocol === 'file:') {
        const gaOpts = { storage: 'none' };

        if (window.localStorage) {
          gaOpts.clientId = localStorage.getItem(GA_LOCAL_STORAGE_KEY);
          ga('create', opts.gaId, gaOpts);
          ga((tracker) => {
            localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
          });
        } else {
          ga('create', opts.gaId, gaOpts);
        }

        ga('set', 'location', opts.fileUrl);
        ga('set', 'checkProtocolTask', null);
        ga('send', 'pageview');
      } else {
        ga('create', opts.gaId, 'auto');
        ga('send', 'pageview');
      }

      initExeptionLogging(opts);
      loaded = true;
    } catch (e) {
      // noop
    }
  },

  event(event) {
    let { category, action, label } = event;

    label = (label === null || label === undefined) ? '-' : label;

    log('[EVENT]', `[${category}]`, `[${action}]`, `[${label}]`);
    try {
      ga('send', 'event', category, action, label);
    } catch (e) {
      // noop
    }
  },

  exception(exceptionDescription, fatal = false) {
    log('[EXCEPTION]', exceptionDescription);

    ga('send', 'exception', {
      exDescription: exceptionDescription,
      exFatal: fatal, // Some Error types might be considered as fatal.
      appName,
      appVersion: '0.1.0',
    });
  },
};


export default analytics;
