import React, { Component } from 'react';
import cn from 'classnames';

import { content } from 'common/language/language';
import FadeInOut from './HOCs/FadeInOut';
import ArtworkHangForm from './ArtworkHangForm';
import Icon from './Icon';
import Button from './Button';
import onResize from '../utils/onResize';
import imgRenderSize from '../utils/imgRenderSize';

function stripHtml(html) {
  const temp = document.createElement("div");
  temp.innerHTML = html;
  return temp.textContent || temp.innerText || "";
}

export default class ArtworkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false,
      showHangForm: false,
      winWidth: window.innerWidth, // these are on state to force re-render on screen resize.
      winHeight: window.innerHeight,
    };

    this.imageFailed = false;
    this.image = null; // defined as ref
    this.handleImgLoad = this.handleImgLoad.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.closeSelf = this.closeSelf.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.toggleHangForm = this.toggleHangForm.bind(this);
    this.showHangForm = this.showHangForm.bind(this);
    this.actions = this.actions.bind(this);
    this.handleImgError = this.handleImgError.bind(this);
  }

  componentDidMount() {
    if (this.image && this.image.complete) {
      // if image is already cached delay a little for transitions
      setTimeout(() => this.setState({ imageLoaded: true }), 800);
    }
    this.resizeHandler = onResize(() => {
      this.setState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
    });
  }

  componentWillUnmount() {
    this.resizeHandler.off();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.item === null || this.props.item === null) { return; }

    if (nextProps.item.artworkId !== this.props.item.artworkId) {
      this.imageFailed = false; // if an image fails this is required to reset on the next one.
    }
  }

  componentDidUpdate() {
    // if image is already cached onLoad may not fire so handle it manually
    if (!this.state.imageLoaded && (this.image && this.image.complete)) {
      setTimeout(() => this.setState({ imageLoaded: true }), 800);
    }
  }

  handleImgLoad() {
    setTimeout(() => this.setState({ imageLoaded: true }), 300);
  }

  handleImgError(err) {
    err.preventDefault();
    // if our fallback errors dont start an infinite loop
    if (this.imageFailed) { return; }

    const { config, item } = this.props;
    err.target.src = config.img.fallback(item.artworkImgId);
    this.setState({ imageLoaded: true });
  }

  handleCloseClick(e) {
    e.preventDefault();
    this.closeSelf();
  }

  closeSelf() {
    const { deactivateFunc } = this.props;

    this.image = null;
    this.setState({ imageLoaded: false });

    deactivateFunc();
  }

  toggleHangForm(state) {
    this.setState({ showHangForm: state });
  }

  showHangForm() {
    const { item, analytics, config } = this.props;
    this.toggleHangForm(true);
    analytics.event({
      category: 'Artwork Selected',
      action: `${item.artworkId} | ${item.title}`,
      label: config.artworkUrl(item.artworkId),
    });

    analytics.event({ category: 'Artwork Submission Flow', action: 'Step: Begin' });
  }

  actions() {
    return (
      <div className="artwork-modal__actions modal__actions">
        <a className="modal__back" onClick={this.handleCloseClick}>
          <Icon icon="larr" /> { content('labels.back') }
        </a>
        { this.props.userCanPost()
          ? (
            <a className="modal__send" onClick={this.showHangForm}>
              { content('byod.artwork.send-to-artwall') } <Icon icon="rarr" />
            </a>
          )
          : (
            <a className="modal__send modal__send--disabled" onClick={() => null}>
              { content('byod.artwork.send-to-artwall') } <Icon icon="rarr" />
            </a>
          )
        }
      </div>
    );
  }

  renderModal() {
    const { item, config, server, analytics, userCanPost } = this.props;
    const { imageLoaded, showHangForm, winWidth, winHeight } = this.state;

    const isStackedLayout = winWidth < 1050; // breaks at 1050px

    const minW = 320;
    const maxW = 1920;

    const ctrWidth = Math.min(maxW, Math.max(minW, winWidth));
    const ctrHeight = isStackedLayout ? winHeight * 0.6 : winHeight * 0.8; // 60vh / 70vh

    const imgSize = ctrWidth > 900 ? 'xl' : 'lg';
    const imgSrc = config.img[imgSize](item.artworkImgId);

    let maxImgWidth;
    let maxImgHeight;

    if (isStackedLayout) {
      maxImgWidth = ctrWidth;
      maxImgHeight = ctrHeight - 60;
    } else {
      const sideBarWidth = 420 + 30 + 30;
      maxImgWidth = ctrWidth - sideBarWidth;
      maxImgHeight = ctrHeight;
    }

    const { width, height } = imgRenderSize(item.width, item.height, maxImgWidth, maxImgHeight);

    const artworkModalClasses = cn(
      'artwork-modal--visible',
      {
        'artwork-modal--img-loading': !imageLoaded,
        'artwork-modal--img-loaded': imageLoaded,
        'artwork-modal--hangform-visible': showHangForm,
      },
    );

    return (
      <div className={`artwork-modal ${artworkModalClasses}`}>

        <div className="artwork-modal__inner">

          <div className="artwork-modal__img-wrapper">
            <div className="artwork-modal__img-inner">
              <div className="modal__actions flex flex-ai-center lg:h-20">
                <a className="modal__back" onClick={this.handleCloseClick}>
                  <Icon icon="larr" /> { content('labels.back') }
                </a>
              </div>

              <div className="flex flex-column flex-jc-center flex-grow-1">
                <img
                  style={{ width, height }}
                  className="artwork-modal__img"
                  ref={img => this.image = img}
                  src={imgSrc}
                  onLoad={this.handleImgLoad}
                  onError={this.handleImgError}
                />
              </div>
            </div>

            <span className="artwork-modal__img-loader">
              { content('byod.artwork.loading-image') }
            </span>
          </div>

          <div className="artwork-modal-info lg:mt-20 lg:flex lg:flex-column">
            <div className="artwork-modal-btn lg:mb-12">
              <Button disabled={!userCanPost()} onClick={this.showHangForm}>
                { content('byod.artwork.send-to-artwall') }
              </Button>
            </div>

            <p>
              <span className="artist">
                { item.artist }
              </span><br />
              <span className="title">
                { item.title }
              </span>
              <span className="date">
                { item.date }
              </span>
              <br />
              <span className="materials">
                { item.materials }
              </span>
            </p>

            <div className="lg:flex lg:flex-column lg:flex-jc-end lg:flex-grow-1 lg:flex-order-last">
              <div className="credit">
                { item.creditLine }<br />
                { stripHtml(item.acknowledgement) }
              </div>
              <div className="reg-number">
                Te&nbsp;Papa ({ item.regNumber })
              </div>
            </div>

          </div>
        </div>

        <FadeInOut>
          <ArtworkHangForm
            closeForm={this.toggleHangForm.bind(this, false)}
            closeSelection={this.closeSelf}
            item={item}
            active={this.state.showHangForm}
            setSubmittedPost={this.props.setSubmittedPost}
            showPostMsg={this.props.showPostMsg}
            server={server}
            analytics={analytics}
            config={config}
            userCanPost={userCanPost}
          />
        </FadeInOut>
      </div>
    );
  }

  render() {
    const { item } = this.props;

    return (
      <FadeInOut>
        { item === null ? null : this.renderModal() }
      </FadeInOut>
    );
  }
}
