
export default function timeToDisplay(seconds) {
  if (typeof seconds !== 'number') { return '-'; }

  if (seconds < 0) { return '0:00'; }
  if (seconds > 3600) { return '> 60'; }
  // if (seconds < 60) { return '< 1'; }

  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 3600 % 60);

  return `${`${m}` + ':'}${`00${s}`.slice(-2)}`;
}
