import './polyfills';

import 'set/sass/byod.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';

import artwallCommonConf from 'set/config.js';

import Server from './server';
import analytics from './analytics';
// import registerServiceWorker from './utils/registerServiceWorker';

import Artwall from './components/Artwall';
import Inputs from './components/Inputs';

const env = window.APP_ENV || 'develop';
const dev = env === 'develop';

analytics.init({
  gaId: artwallCommonConf.analyticsKey,
  debug: dev,
  appName: 'Art Wall BYOD',
});

const config = Object.assign({}, artwallCommonConf, {
  appNameSpace: 'byod',
  initialLoadSize: 50,
  incrementalLoadSize: 50,
  loadItemsProgressively: !true,
  loadTriggerOffset: 1000,
  hasGutter: true,
  shuffleOnLoad: true,
  requireLocation: true,
  showPostBanners: true,
  autoClosePostMessage: false,
  inputs: Inputs, // see notes in file for what is going on here.
  autoFocusFields: document.body.offsetWidth > 1000,
  defaultLang: 'en',
});
const server = new Server();

const container = document.getElementById('mountnode');

const root = createRoot(container);
root.render(
  <Artwall
    server={server}
    analytics={analytics}
    config={config}
  />
);
