import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

import conf from "set/config.js";

/* Seconds after which a pingtime is considered stale. */
const PING_TIMEOUT = 90;

const fbConf = conf.firebase;
const ENV = process.env.NODE_ENV || "development";

class HealthCheck {
  constructor(database, callback) {
    this.callback = callback;
    this.pings = {};

    this.update = this.update.bind(this);
    this.interval = setInterval(this.update, 2000);

    database.ref("status").on("value", (snapshot) => {
      const value = snapshot.val();
      this.pings.functions =
        value.functions === undefined ? 0 : value.functions.last_ping;
      this.pings.wall = value.wall === undefined ? 0 : value.wall.last_ping;
    });
    this.setState("connecting");
  }

  update() {
    if (this.pings.functions === undefined || this.pings.wall === undefined) {
      /* No pings from firebase yet. Still connecting. */
      this.setState("connecting");
      return;
    }

    const now = Date.now();

    const healthy =
      this.pings.functions > now - PING_TIMEOUT * 1000 &&
      this.pings.wall > now - PING_TIMEOUT * 1000;

    // TODO: igure out why this doesn't work
    // if (ENV === 'production') {
    // this.setState(healthy ? 'up' : 'down');
    // } else {
    this.setState("up");
    // }
  }

  setState(state) {
    if (this.state !== state) {
      this.callback(state);
      this.state = state;
    }
  }
}

class Server {
  constructor() {
    this.firebase = firebase;
    this.database = null;
    this.postsDB = null;
    this.uid = null;
    this.connected = false;
    this.connect();
  }

  connect() {
    this.firebase.initializeApp(fbConf);
    if (this.firebase.app instanceof Function && this.firebase.app().options) {
      this.connected = true;
      this.initialiseFirebase();
    }
  }

  initialiseFirebase() {
    this.database = this.firebase.database();
    this.postsDB = this.database.ref("posts");

    this.queueDB = this.database.ref("queue");
    this.currentPostDB = this.database.ref("current_post");

    this.configDB = this.database.ref("config");
  }

  watchHealth(onChange) {
    this.healthcheck = new HealthCheck(this.database, onChange);
  }

  watchConnection(onChange) {
    this.database.ref(".info/connected").on("value", (snap) => {
      onChange(snap.val());
    });
  }

  watchConfig(onChange) {
    this.configDB.off();

    if (onChange !== null) {
      this.configDB.on("value", (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          onChange(data);
        } else {
          onChange([]);
        }
      });
    }
  }

  startSessionForPosting(cb) {
    return new Promise((resolve, reject) => {
      if (this.firebase.auth().currentUser) {
        resolve(this.firebase.auth().currentUser);
      } else {
        this.firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            this.uid = user.uid;
            resolve(user);
          } else {
            this.firebase
              .auth()
              .signInAnonymously()
              .catch((error) => {
                reject(error);
              });
          }
        });
      }
    });
  }

  addPost(newPost) {
    return new Promise((resolve, reject) => {
      this.startSessionForPosting().then((user) => {
        newPost.userId = user.uid;

        // set a timeout of 30 seconds if post hasnt resolved by then.
        setTimeout(() => {
          reject("timeout");
        }, 30000);

        this.postsDB
          .push(newPost)
          .then((resp) => {
            resp.on("value", (dataSnapshot) => {
              const post = dataSnapshot.val();
              if (typeof post.valid === "boolean") {
                resp.off();
                if (post.valid === true) {
                  resolve(post);
                } else if (post.valid === false) {
                  reject("post-invalid");
                }
              }
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }

  queue(updateQueue) {
    this.queueDB.off();
    this.queueDB.on("value", (snapshot) => {
      const queue = snapshot.val();
      updateQueue(queue);
    });
  }

  currentPost(updateCurrentPost) {
    this.currentPostDB.off();
    this.currentPostDB.on("value", (snapshot) => {
      const post = snapshot.val();
      updateCurrentPost(post);
    });
  }
}

export default Server;
