/* eslint-disable max-len */
const mi = {
  labels: {
    'back-to-top': 'E oki',
    'dismiss': 'whakakore',
    'close': 'Katia',
    'close-window': 'Katia te wini',
    'cancel': 'Whakakorea',
    'next': 'Haere tonu',
    'loading': 'Uta...',
  },
  byod: {
    header: {
      'brand': '<span>Papa Taonga</span><div class="separator-vertical"></div><span>Te Papa</span>',
      'tagline': 'HE TAONGA, WHAKAIRIA!',
      '__byod-instruction': 'not translated',
      'send-instruction': '<p>Ko tēhea te taonga pai ki a koe?</p><p>Pāwhiria, ka whakaatuhia ki Te Matatini</p>',
    },
    banner: {
      '__offline': 'not translated',
      '__down': 'not translated',
      'time-to-display': 'Ākuni, ā te <strong>{}</strong>, ka whakaatuhia te taonga.',
      'on-display-now': 'Kei papa whakaata te taonga e iri ana',
    },
    set: {
      'reached-end': 'Ka pai! Kua tae atu koe ki te mutunga o <span class="nowrap">‘{}’</span>',
      '__another-set': 'not translated',
    },
    artwork: {
      'send-to-artwall': 'Tukuna ki te Papa Taonga',
      'loading-image': 'Uta...',
    },
    form: {
      'send': 'Tukuna ki te Papa Taonga',
      'sending': 'Tuku...',
      'go-back': 'E hoki',
      'skip': 'Me hipa atu tēnei mahi',
      'name': {
        'heading': 'Anō te pai!',
        'text': 'Whakautua ēnei pātai e toru, ka tukuna te taonga.',
        'label': 'Ko wai tō ingoa?',
        'example': 'Tui',
      },
      origin: {
        'heading': 'Tēnā koe, {}!',
        'text': 'Whakaatuhia, nō hea koe.',
        'label': 'Nō hea koe?',
        'example': 'Te Awamutu',
      },
      comment: {
        'heading': 'Pātai whakamutunga.',
        'text': 'Whakaatuhia te take i pai ai tēnei taonga ki a koe.',
        'label': 'He aha te take i pai ai tēnei taonga ki a koe?',
        'example': 'Nā te mea...',
        'chars-remaining': 'E {} ngā reta kei te toe',
      },
      confirm: {
        'heading': 'He tika tēnei?',
        'name-and-origin': 'Nā <strong>{}</strong> mai i <strong>{}</strong> tēnei taonga i whakairi.',
        'name-only': 'Nā <strong>{}</strong> tēnei taonga i whakairi',
      },
      error: {
        '__heading': 'not translated',
        '__text': 'not translated',
      },
    },
    post: {
      'heading': 'Kua oti katoa!',
      'text': '<p>Kua tukuna tēnei taonga ki Te Papa Taonga – {}.</p>',
      'text-position-in-queue': 'Koia te mea {} i te rārangi whakaatu',
      'text-position-fallback': 'Kei te rārangi whakaatu ināianei',
      'text-for-backlog': 'Kua tukuna tēnei taonga ki Te Papa Taonga',
      'time-to-display': 'Ākuni, ka whakaatuhia te taonga, ā te',
      '__time-to-autoclose': 'not translated',
      'positions': [
        'e whai nei', // next
        'tuarua', // second
        'tuatoru', 'tuawhā', 'tuarima', 'tuaono', 'tuawhitu',
        'tuawaru', 'tuaiwa', 'tuangahurui', // tenth
      ],
    },
  },
  validations: {
    'invalid-chars': {
      'name': 'Ka aroha, kāre wētahi o wēnei reta, kupu rānei e whakaaetia ana.',
      'comment': 'Ka aroha, kāre wētahi o wēnei reta, kupu rānei e whakaaetia ana.',
      'origin': 'Ka aroha, kāre wētahi o wēnei reta, kupu rānei e whakaaetia ana.',
    },
    'junk-detected': {
      'name': 'Ka aroha, kāre wētahi o wēnei reta i te whakaaetia.',
      'comment': 'Ka aroha, kāre wētahi o wēnei reta i te whakaaetia.',
      'origin': 'Ka aroha, kāre wētahi o wēnei reta i te whakaaetia.',
    },
    'rude-detected': {
      'name': 'Tēnā koa, patopatohia anōtia tō ingoa',
      'comment': 'Tēnā koa, patopatohia anōtia te take i kōwhiritia ai te taonga',
      'origin': 'Tēnā koa, patopatohia anōtia te tūwāhi',
    },
    'required': {
      'name': 'Aue! Whakaurua tō ingoa ki raro.',
      'comment': 'Aue! Whakaurua tō he kōrero ki raro.',
      'origin': 'Aue! Whakaurua tō tō tūwāhi ki raro.',
    },
    'too-short': {
      'name': 'Aue! He poto rawa tō ingoa. Kia {} ngā reta, nui ake rānei.',
      'comment': 'Aue! He poto rawa tō kōrero. Kia {} ngā reta, nui ake rānei.',
      'origin': 'Aue! He poto rawa tō tūwāhi. Kia {} ngā reta, nui ake rānei.',
    },
    'too-long': {
      'name': 'Aue! He roa rawa tō ingoa. Kia iti ake i te {} ngā reta te nui.',
      'comment': 'Aue! He roa rawa tō kōrero. Kia iti ake i te {} ngā reta te nui.',
      'origin': 'Aue! He roa rawa tō tūwāhi. Kia iti ake i te {} ngā reta te nui.',
    },
  },
  wall: {
    'application-name': 'Papa Taonga',
    'application-loading': 'Kei te whakaritea te Papa Taonga',
    sidebar: {
      'name-only': 'Nā <strong>{}</strong> tēnei taonga i whakairi.',
      'name-and-origin': 'Nā <strong>{}</strong> mai i <strong>{}</strong> tēnei taonga i whakairi.',
      '__circa': 'not translated',
    },
  },
};

export default mi;
