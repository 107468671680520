import React from 'react';
import cn from 'classnames';
import { content, htmlContent } from 'common/language/language';
import SetHeader from 'set/components/Header';

import timeToDisplay from '../utils/timeToDisplay';


function Banner(props) {
  const {
    offline, status, upcomingPostBanner, timeUntilDisplay,
    notifySubmissionOnWall, clearPostOnWallNotification,
  } = props;

  if (offline) {
    return (
      <div className="banner banner--info">
        <p>
          { content('byod.banner.offline') }
        </p>
      </div>
    );
  } if (status === 'connecting') {
    return null;
  } if (status === 'down') {
    return (
      <div className="banner banner--info">
        <p>{ content('byod.banner.down') }</p>
      </div>
    );
  } if (upcomingPostBanner) {
    return (
      <div className="banner">
        {htmlContent('h4', 'byod.banner.time-to-display', timeToDisplay(timeUntilDisplay))}
      </div>
    );
  } if (notifySubmissionOnWall) {
    return (
      <div className="banner" onClick={clearPostOnWallNotification}>
        {htmlContent('h4', 'byod.banner.on-display-now')}
        <p>{content('labels.close')}</p>
      </div>
    );
  }
  return null;
}


export default function Header(props) {
  const {
    notifySubmissionOnWall, clearPostOnWallNotification, upcomingPostBanner,
    timeUntilDisplay, offline, status,
  } = props;

  const classes = cn('header', {
    'header-banner-1': upcomingPostBanner, // || offline || status !== 'up',
    'header-banner-2': notifySubmissionOnWall,
  });

  return (
    <div>
      <SetHeader />

      <Banner
        upcomingPostBanner={upcomingPostBanner}
        notifySubmissionOnWall={notifySubmissionOnWall}
        clearPostOnWallNotification={clearPostOnWallNotification}
        timeUntilDisplay={timeUntilDisplay}
        offline={offline}
        status={status}
      />

    </div>
  );
}
