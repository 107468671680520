import React, { Component } from 'react';
import { content, htmlContent } from 'common/language/language';
import Icon from '../Icon';
import Button from '../Button';

export default class CommentTab extends Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.focusInput();
  }

  handleNext() {
    if (this.props.next() === false) {
      this.focusInput();
    }
  }

  focusInput() {
    if (this.Input && this.Input.input) {
      this.Input.input.focus();
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && event.metaKey) {
      this.handleNext();
    }
  }

  render() {
    const { comment, conf, errors, change, skip, next, TextArea, userCanPost } = this.props;

    const smallScreen = document.body.offsetWidth < 1000;

    const { maxCommentLength } = conf;

    const hasErrors = errors.length > 0;
    return (
      <div className={`comment-tab ${hasErrors ? 'contains-errors' : ''}`}>
        <div className="tab-content">
          <label htmlFor="usercomment-input">
            { htmlContent('span', 'byod.form.comment.label') }
          </label>

          { hasErrors && (
            <div className="formfield__errors">
              { errors.map((err, i) => <p key={`comment-error-${i}`}>{err}</p>) }
            </div>
          ) }

          <div className="relative">
            <TextArea
              id="usercomment-input"
              placeholder={content('byod.form.comment.example')}
              rows={smallScreen ? 4 : 4}
              className="hangform__formfield"
              value={comment}
              onChange={change}
              next={this.handleNext} // for keyboard on kiosk
              onKeyPress={this.handleKeyPress} // only on byod
              ref={el => this.Input = el}
            />
            <div className="usercomment-characters">
              <span>
                { content('byod.form.comment.chars-remaining', (maxCommentLength - comment.length)) }
              </span>
            </div>
          </div>
          <div className="text-right mt-3">
            <Button disabled={!userCanPost()} onClick={next}>
              { content('byod.form.send') } <Icon icon="rarr" />
            </Button>
          </div>
        </div>

      </div>
    );
  }
}
