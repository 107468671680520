/*
    What on earth is this file for?
    Because kiosk and byod share the same code base but kiosk needs an on screen keyboard.
    I didn't want to make it just a config option because that would require adding another 30kb
    of js to an already large bundle on byod that would never be used. So instead we have dependency
    injection at the build level.

    These need to extend react.component because the input ref is accessed at the consumer level.
 */

import React, { Component } from 'react';

class TextArea extends Component {
  render() {
    return (
      <textarea
        tabIndex="0"
        id={this.props.id}
        className={this.props.className}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.props.onChange}
        onKeyDown={this.props.onKeyPress}
        rows={this.props.rows}
        ref={el => this.input = el}
      />
    );
  }
}

class Input extends Component {
  render() {
    return (
      <input
        tabIndex="0"
        id={this.props.id}
        className={this.props.className}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.props.onChange}
        onKeyPress={this.props.onKeyPress}
        ref={el => this.input = el}
        spellCheck={this.props.spellCheck}
        autoComplete={this.props.autoComplete}
      />
    );
  }
}

export default { TextArea, Input };
