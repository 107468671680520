import React from 'react';
import { content } from 'common/language/language';

export default function ErrorTab(props) {
  return (
    <div className="error-tab">
      <div className="info-box">
        <h4>{ content('byod.form.error.heading') }</h4>
        <p>{ content('byod.form.error.text') }</p>
      </div>
      <p>
        You can <a onClick={props.back}>try again</a>,&nbsp;
        <a onClick={props.close}> choose another artwork</a>&nbsp;
        and if the problem persists try <a onClick={() => window.location.reload()}>
        reloading the app</a>.
      </p>
    </div>
  );
}
