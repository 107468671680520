import React, { Component } from 'react';
import cn from 'classnames';
import { content, htmlContent } from 'common/language/language';
import { XMasonry, XBlock } from './react-xmasonry';

import debounce from '../utils/debounce';

import Artwork from './Artwork';
import Footer from './Footer';
import theme from 'set/theme';


/* The ArtworkGrid takes the art dataset and a list of artwork ID's to
 * display as properties. It will then progressively render the images
 * and call the onSelected callback with the properties of an artwork
 * if one is selected.
 *
 * The ArtworkGrid also takes care of rearranging the images when the
 * browser window is resized.
 */
export default class ArtworkGrid extends Component {
  constructor(props) {
    super(props);

    this.config = this.props.config;

    this.state = {
      loading: false,
      doneLoading: false,
      targetItemWidth: this.targetImgWidth(),
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.loadNextItems = this.loadNextItems.bind(this);
    this.loadAllNextItems = this.loadAllNextItems.bind(this);
  }

  componentDidMount() {
    this.debouncedHandleScroll = debounce(this.handleScroll, 150);
    window.addEventListener('scroll', this.debouncedHandleScroll);

    this.debouncedHandleResize = debounce(this.handleResize, 350);
    window.addEventListener('resize', this.debouncedHandleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.debouncedHandleScroll);
    window.removeEventListener('resize', this.debouncedHandleResize);
    if (this.loadTimer) {
      clearTimeout(this.loadTimeout);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initialLoadSize } = this.config;

    // Reset the number of displayed items when receiving new items.
    if (nextProps.items !== this.props.items) {
      this.setState({
        doneLoading: false,
        itemsDisplayed: initialLoadSize,
        targetItemsDisplayed: initialLoadSize,
      });
      this.lastPreloaded = null;
    }

    // Force an update when replacing items. This prevents
    // placement glitches that last a few seconds before the grid
    // refreshes.
    if (this.xMasonry) {
      this.xMasonry.update();
    }
  }

  componentDidUpdate() {
    this.preloadNextItems();
  }

  handleResize() {
    this.setState({
      targetItemWidth: this.targetImgWidth(),
    });
  }

  // Responds to scroll event. Loads more images if at the bottom.
  handleScroll() {
    if (this.state.loading || this.allItemsDisplayed()) { return; }

    const { loadTriggerOffset, loadItemsProgressively, incrementalLoadSize } = this.config;

    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset + loadTriggerOffset;

    if (windowBottom >= docHeight - windowHeight) {
      const { analytics, items } = this.props;
      const targetItemsDisplayed = Math.min(
        this.props.items.length,
        this.state.itemsDisplayed + incrementalLoadSize,
      );

      this.setState({
        loading: true,
        targetItemsDisplayed,
      }, () => {
        if (loadItemsProgressively) {
          this.loadNextItems();
        } else {
          this.loadAllNextItems();
        }
      });

      analytics.event({
        category: 'Artworks Loaded',
        action: `${Math.round(targetItemsDisplayed / items.length * 100)}%`,
        label: this.props.activeSet,
      });
    }
  }

  // Progressively load next items 1 by 1.
  loadNextItems() {
    const { itemsDisplayed, targetItemsDisplayed } = this.state;
    const { items } = this.props;

    this.loadTimeout = null;

    if (targetItemsDisplayed > itemsDisplayed) {
      this.setState({
        loading: true,
        itemsDisplayed: itemsDisplayed + 1,
      });
      this.loadTimeout = setTimeout(this.loadNextItems, 100);
    } else {
      this.setState({
        loading: false,
        doneLoading: itemsDisplayed === items.length,
      });
      this.preloadNextItems();
    }
  }

  // Load next items in a chunk.
  loadAllNextItems() {
    const { itemsDisplayed, targetItemsDisplayed } = this.state;

    if (targetItemsDisplayed > itemsDisplayed) {
      this.setState({
        loading: false,
        itemsDisplayed: targetItemsDisplayed,
        doneLoading: targetItemsDisplayed === this.props.items.length,
      }, this.preloadNextItems());
    }
  }

  allItemsDisplayed() {
    return (this.state.itemsDisplayed >= this.props.items.length);
  }

  // Preloads images from the list of items.
  preloadNextItems() {
    const { incrementalLoadSize } = this.config;
    const { targetItemsDisplayed, itemsDisplayed } = this.state;
    const { art, items } = this.props;

    this.lastPreloaded = this.lastPreloaded || itemsDisplayed;

    const preloadFrom = this.lastPreloaded;
    const preloadTo = Math.min(
      items.length,
      targetItemsDisplayed + incrementalLoadSize,
    );

    if (preloadFrom >= preloadTo) {
      return;
    }

    const nextItems = items.slice(preloadFrom, preloadTo);

    nextItems.forEach((item) => {
      setTimeout(() => { // make this async
        const id = art[item].artworkImgId;
        const img = new Image();
        const src = document.body.offsetWidth < 420
          ? this.config.img.sm(id)
          : this.config.img.md(id);
        img.src = src;
      });
    });

    this.lastPreloaded = preloadTo;
  }

  targetImgWidth() {
    let itemWidth = 300;
    if (theme.wallColumns) {
      itemWidth = (window.innerWidth / theme.wallColumns) - 5;
    }
    return (window.innerWidth < 900) ? (window.innerWidth / 2) - 5 : itemWidth;
    // return (window.innerWidth < 900) ? (window.innerWidth / 2) - 5 : 300;
  }

  loadedNotification() {
    const { activeSet, setCount } = this.props;
    const { doneLoading } = this.state;

    const classNames = cn(
      'artwork-grid-loaded',
      { 'artwork-grid-loaded--hidden': !doneLoading },
    );

    return (
      <div className={classNames}>
        <p>
          {htmlContent('span', 'byod.set.reached-end', this.state.itemsDisplayed)}
        </p>
        {setCount > 1 && (
          <p>{content('byod.set.another-set')}</p>
        )}
      </div>
    );
  }

  footer() {
    const { doneLoading } = this.state;

    return doneLoading ? (<Footer />) : (null);
  }

  renderItems() {
    const { itemsDisplayed } = this.state;
    const { art, analytics, onSelect } = this.props;

    const colWidth = this.xMasonry
      ? Math.floor(this.xMasonry.containerWidth / this.xMasonry.columns)
      : 0;

    const displayedItems = this.props.items.slice(0, itemsDisplayed);

    return displayedItems.map((_item) => {
      const item = art[_item];
      return item && (
        <XBlock key={item.artworkId} width={1}>
          <Artwork
            item={item}
            setActiveFunc={onSelect}
            analytics={analytics}
            colWidth={colWidth - 10} // -10 is for padding around image
            config={this.config}
          />
        </XBlock>
      );
    });
  }

  render() {
    const { hasGutter } = this.config;
    const { targetItemWidth } = this.state;

    const className = cn(
      'artwork-grid ',
      { 'artwork-grid--with-gutter': hasGutter },
    );
    // console.log(targetItemWidth);

    return (
      <div>
        <div className={className}>

          <XMasonry
            // maxColumns={2}
            targetBlockWidth={targetItemWidth}
            updateOnFontLoad={false}
            responsive={false} // Resize is managed internally
            ref={(ref) => { this.xMasonry = ref; }}
          >
            {this.renderItems()}
          </XMasonry>
          {this.loadedNotification()}

        </div>
        {this.footer()}
      </div>
    );
  }
}
