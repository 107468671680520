import React, { Component } from 'react';

import FadeInOut from '../HOCs/FadeInOut';
import Icon from '../Icon';
import Button from '../Button';
import { content, htmlContent } from 'common/language/language';


export default class ConfirmTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLegals: false,
    };
    this.showLegals = this.showLegals.bind(this);
    this.hideLegals = this.hideLegals.bind(this);
  }

  showLegals(e) {
    e.preventDefault();

    this.props.analytics.event({
      category: 'Legal Terms',
      action: 'User Opened Terms',
    });

    this.setState({ showLegals: true });
  }

  hideLegals(e) {
    e.preventDefault();
    // do analytics
    this.setState({ showLegals: false });
  }

  render() {
    const {
      comment, name, next, origin, userCanPost,
    } = this.props;

    return (
      <div>
        <div className="tab-content">
          <label>{ content('byod.form.confirm.heading') }</label>
          <div className="confirm-block">
            { comment && <p className="confirm-comment"><strong>{comment}</strong></p> }
            { origin && htmlContent('p', 'byod.form.confirm.name-and-origin', name, origin) }
            { !origin && htmlContent('p', 'byod.form.confirm.name-only', name) }
          </div>
        </div>

        <div className="flex flex-jc-end">
          <Button disabled={!userCanPost()} fullWidth={true} onClick={next}>
            { content('byod.form.send') } <Icon icon="rarr" />
          </Button>
        </div>

        <FadeInOut>
          { this.state.showLegals && (
              <div className="legals-modal">
                <div className="legals-close-wrapper">
                  <a className="close-legals-modal" onClick={this.hideLegals}>
                     { content('labels.close') } <Icon icon="x" />
                  </a>
                </div>

                <div className="legals-modal--inner">
                  <iframe src="legals.html" frameBorder="0" />
                </div>
              </div>
          ) }
        </FadeInOut>

      </div>
    );
  }
}
