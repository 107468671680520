import React from 'react';
import get from 'lodash/get';

import en from 'set/language/en';
import mi from 'set/language/mi';

const isProd = process.env.NOD_ENV === 'production';

const langs = { en, mi };
let currentLang = 'en';
const defaultLang = 'en';
const defaultDict = langs[defaultLang];

const setLang = (lang) => {
  if (lang in langs) {
    currentLang = lang;
  } else {
    currentLang = defaultLang;
  }
};

const tokenise = (str, args) => {
  const replaced = args.reduce((output, token) => {
    // if (output.indexOf('{}') === -1) {
    //   return isProd ? '' : `${output} {{ ${token} }}`;
    // }
    return output.replace('{}', token);
  }, str);

  return replaced;
};

const getDefault = (path) => {
  const notFound = isProd ? '' : `[[Missing language - ${path}]]`;
  return get(defaultDict, path, notFound);
};

const content = (path, ...args) => {
  const dict = langs[currentLang];
  const str = get(dict, path, getDefault(path));
  return args.length > 0 ? tokenise(str, args) : str;
};

const rawContent = (path) => {
  const dict = langs[currentLang];
  return get(dict, path, getDefault(path));
};


const htmlContent = (pathOrElType, ...args) => {
  let elType = 'div';
  let path = pathOrElType;

  // if first param is an element type, adjust args
  if (['div', 'span', 'p', 'h1', 'h2', 'h3', 'h4'].indexOf(pathOrElType) > -1) {
    elType = path;
    [path, ...args] = args; // eslint-disable-line no-param-reassign
  }

  return React.createElement(elType, {
    dangerouslySetInnerHTML: { __html: content(path, ...args) },
  });
};

const tokeniseStr = (str = '', ...args) => tokenise(str, args);

export {
  content,
  rawContent,
  setLang,
  htmlContent,
  tokeniseStr,
};
