module.exports = {
  maxCommentLength: 140,
  minCommentLength: 5,

  // name
  maxNameLength: 40,
  minNameLength: 2,

  // origin / from
  maxOriginLength: 40,
  minOriginLength: 2,

  nameWhitelist: ['dick', 'dong', 'wang', 'hoe', 'hori', 'koon', 'kum'],
};
