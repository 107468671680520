import React from 'react';
import theme from '../theme';

export default function Footer(props) {
  const {
    sponsors
  } = theme;
  return (
    <div className="footer">
      <div className="max-w-sm mx-auto">
        {sponsors && (
          <div className="flex flex-jc-evenly">
            {sponsors.map(sponsor => (
              <img
                src={sponsor.src}
                alt={sponsor.name}
                height="88"
                width="200"
              />
            ))}
          </div>
        )}
        <div className="mt-24">
          <a
            target="_blank"
            href="https://www.tepapa.govt.nz/privacy"
            rel="noopener noreferrer"
            className="color-white text-xs"
          >
            Privacy policy
          </a>
        </div>

      </div>
    </div>
  );
}
