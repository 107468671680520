import theme from '../theme';

/* eslint-disable max-len */
const en = {
  labels: {
    'back-to-top': 'Back to Top',
    // 'dismiss': 'Dismiss',
    'close': 'Close',
    'back': 'Back',
    'close-window': 'Close window',
    'cancel': 'Cancel',
    'next': 'Next',
    'loading': 'Loading...',
  },
  byod: {
    header: {
      'mi-instruction': theme.introText.mi,
      'en-instruction': theme.introText.en,
    },
    banner: {
      'offline': 'Uh oh, it seems you have gone offline.',
      'down': 'We are offline at the moment. Posting is disabled but you may still browse our artworks.',
      'time-to-display': `Your ${theme.wallVerbs.singular} will be displayed in&nbsp;<strong>{}</strong>`,
      'on-display-now': `Your ${theme.wallVerbs.singular} is on display now`,
    },
    set: {
      'reached-end': theme.footerCopy,
      'another-set': 'Why don’t you check out another set?',
    },
    artwork: {
      'send-to-artwall': 'Select',
      'loading-image': '',
    },
    form: {
      send: theme.sendLabel,
      sending: 'Sending...',
      'go-back': 'Ki Muri - back',
      skip: 'Tīpokana - skip this step',
      name: {
        label: 'Ko wai tō ingoa?<br /><span class="font-light">What’s your name?</span>',
        example: 'Clementine',
      },
      origin: {
        label: 'Nō hea koe?<br /><span class="font-light">Where are you from?</span>',
        example: 'Ōtautahi - Christchurch',
      },
      comment: {
        label: 'Why did you choose this artwork?',
        example: 'Because...',
        'chars-remaining': '{} characters left',
      },
      confirm: {
        heading: 'He tika tēnei - is this correct?',
        'name-and-origin': `<strong>{}</strong> from <strong>{}</strong> chose this ${theme.wallVerbs.singular}`,
        'name-only': `<strong>{}</strong> chose this ${theme.wallVerbs.singular}`,
      },
      error: {
        'heading': 'Auē - Sorry, something went wrong with your submission.',
        'text': 'Our team has been notified and we\'re looking into the problem',
      },
    },
    post: {
      'heading': 'Kua oti katoa - all done!',
      'text': `<p>Your ${theme.wallVerbs.singular} has been sent to ${theme.wallVerbs.wallName}. {}</p>`,
      'text-position-in-queue': 'It’s {} in the queue.',
      'text-position-fallback': 'It’s in the queue',
      'text-for-backlog': `Your ${theme.wallVerbs.singular} has been sent to ${theme.wallVerbs.wallName}.`,
      'time-to-display': `Your ${theme.wallVerbs.singular} will be displayed in`,
      'time-to-autoclose': 'Window closing in {}...',
      'positions': [
        'next', 'second', 'third', 'fourth', 'fifth',
        'sixth', 'seventh', 'eighth', 'ninth', 'tenth',
      ],
    },
  },

  validations: {
    'invalid-chars': {
      'name': 'Auē – sorry, some of these characters are not allowed.',
      'comment': 'Auē – sorry, some of these characters are not allowed.',
      'origin': 'Auē – sorry, some of these characters are not allowed.',
    },
    'junk-detected': {
      'name': 'Hmm, are they real words? Check your spelling or try removing excess letters.',
      'comment': 'Hmm, are they real words? Check your spelling or try removing excess letters.',
      'origin': 'Hmm, are they real words? Check your spelling or try removing excess letters.',
    },
    'rude-detected': {
      'name': 'Taukiri ē – oops! It looks like you’ve used an offensive word. Please rewrite your name.',
      'comment': 'Taukiri ē – oops! It looks like you’ve used an offensive word. Please rewrite your comment.',
      'origin': 'Taukiri ē – oops! It looks like you’ve used an offensive word. Please rewrite your origin.',
    },
    'required': {
      'name': 'Taukiri ē – oops! Please enter your name below.',
      'comment': 'Taukiri ē – oops! Please enter your comment below.',
      'origin': 'Taukiri ē – oops! Please enter your origin below.',
    },
    'too-short': {
      'name': 'Aiii – uh-oh! Your name is too short. It should be at least {} letters.',
      'comment': 'Aiii – uh-oh! Your comment is too short. It should be at least {} letters.',
      'origin': 'Aiii – uh-oh! Your origin is too short. It should be at least {} letters.',
    },
    'too-long': {
      'name': 'Aiii – uh-oh! Your name is too long. It can’t be more than {} letters.',
      'comment': 'Aiii – uh-oh! Your comment is too long. It can’t be more than {} letters.',
      'origin': 'Aiii – uh-oh! Your origin is too long. It can’t be more than {} letters.',
    },
  },
  wall: {
    'application-name': `Pātū Toi | Art Wall`,
    'application-loading': 'E tuku ana... Loading...',
    sidebar: {
      'name-only': `<strong>{}</strong> chose this ${theme.wallVerbs.singular}`,
      'name-and-origin': `<strong>{}</strong> from <strong>{}</strong> chose this ${theme.wallVerbs.singular}`,
      'circa': '{}',
      'tagline': 'Mōu te toi, māu te kōwhiri<br>Your art, your choice.',
      'url': 'tepapa.nz/artwall',
    },
  },
};

export default en;
