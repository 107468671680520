const config = require('../validation.config');

// validation library supplied by dependency injection.
// In the browser these should be on the window. in node require them.
function Valid(dejunk, rudeboy, content = (...args) => args.join(' | ')) {
  if (!dejunk) {
    throw new Error('Validation library dejunk is not available.');
  }

  if (!rudeboy) {
    throw new Error('Validation library rudeboy is not available.');
  }

  this.config = config;

  this.tagsMessage = type => content('invalid-chars', type);
  this.junkMessage = type => content('junk-detected', type);
  this.rudeMessage = type => content('rude-detected', type);
  this.requiredMessage = type => content('required', type);
  this.tooShortMessage = (type, min) => content('too-short', type, min);
  this.tooLongMessage = (type, max) => content('too-long', type, max);

  this.nameWhitelist = config.nameWhitelist;
  this.functionRegex = /\b[\w\.]+\((?:(['"]).*\1)?\)/g;

  this.hasJunk = dejunk.hasJunk;
  this.rude = rudeboy;
}

Valid.prototype.hasTags = function (val) {
  const hasTags = val.indexOf('>') > -1 || val.indexOf('<') > -1;
  const hasFunction = this.functionRegex.test(val);
  return (hasTags || hasFunction);
};

Valid.prototype.userComment = function (comment) {
  const errors = [];

  comment = comment.trim();

  if (comment.length === 0) { return errors; } // non required feld

  // junk
  if (this.hasJunk(comment)) {
    errors.push(this.junkMessage('comment'));
  }

  // rude
  if (this.rude(comment)) {
    errors.push(this.rudeMessage('comment'));
  }

  // tags
  if (this.hasTags(comment)) {
    errors.push(this.tagsMessage('comment'));
  }

  // min length
  if (comment.length < this.config.minCommentLength) {
    errors.push(this.tooShortMessage('comment', this.config.minCommentLength));
  }

  // max length
  if (comment.length > this.config.maxCommentLength) {
    errors.push(this.tooLongMessage('comment', this.config.maxCommentLength));
  }

  return errors;
};

Valid.prototype.userName = function (name) {
  const errors = [];
  name = name.trim();

  // junk
  if (this.hasJunk(name)) {
    errors.push(this.junkMessage('name'));
  }

  // rude
  if (this.rude(name, false, this.nameWhitelist)) {
    errors.push(this.rudeMessage('name'));
  }

  if (this.hasTags(name)) {
    errors.push(this.tagsMessage('name'));
  }

  // min length | required
  if (name.length < this.config.minNameLength) {
    errors.push(name.length === 0 ? this.requiredMessage('name') : this.tooShortMessage('name', this.config.minNameLength));
  }

  // max length
  if (name.length > this.config.maxNameLength) {
    errors.push(this.tooLongMessage('name', this.config.maxNameLength));
  }

  return errors;
};

Valid.prototype.userOrigin = function (origin) {
  const errors = [];
  origin = origin.trim();

  if (origin.length === 0) { return errors; } // non required feld

  // junk
  if (this.hasJunk(origin)) {
    errors.push(this.junkMessage('origin'));
  }

  // rude
  if (this.rude(origin, false)) {
    errors.push(this.rudeMessage('origin'));
  }

  // tags
  if (this.hasTags(origin)) {
    errors.push(this.tagsMessage('origin'));
  }

  // min length | required
  if (origin.length < this.config.minOriginLength) {
    errors.push(this.tooShortMessage('origin', this.config.minOriginLength));
  }

  // max length
  if (origin.length > this.config.maxOriginLength) {
    errors.push(this.tooLongMessage('origin', this.config.maxOriginLength));
  }
  return errors;
};

Valid.prototype.isValidPost = function (post) {
  const commentErrors = this.userComment(post.userComment);
  const nameErrors = this.userName(post.userName);
  const originErrors = this.userOrigin(post.userOrigin);
  return (commentErrors.length === 0 && nameErrors.length === 0 && originErrors.length === 0);
};

module.exports = Valid;
