import React from 'react';
import cn from 'classnames';

export default function Button(props) {
  const { disabled, onClick, children, fullWidth } = props;
  const noop = () => null;
  const classNames = cn(
    'btn',
    'btn--primary btn--large btn--transition',
    { 'btn--full': fullWidth },
    { 'btn--disabled': disabled }
  );

  return (
    <div className={classNames} onClick={disabled ? noop : onClick}>
      { children }
    </div>
  );
}
