import React from 'react';

const ns = 'http://www.w3.org/2000/svg';

export default function Icon({ icon }) {
  const icons = {
    x: (
      <svg width="12" height="12" viewBox="0 0 12 12" xmlns={ns}>
        <path d="M10.415 11.973l1.558-1.586L7.6 6.014l4.4-4.4L10.415 0l-4.43 4.428L1.56 0 0 1.558l4.428 4.428L0 10.416 1.585 12l4.43-4.428" fillRule="evenodd" />
      </svg>
    ),
    larr: (
      <svg width="9" height="16" viewBox="0 0 9 16" xmlns={ns}>
        <path d="M9 14.432L7.372 15.84.64 7.92 7.372 0 9 1.452 3.456 7.92" fillRule="evenodd" />
      </svg>
    ),
    rarr: (
      <svg width="9" height="16" viewBox="0 0 9 16" xmlns={ns}>
        <path d="M0 14.432l1.628 1.408L8.36 7.92 1.628 0 0 1.452 5.544 7.92" fillRule="evenodd" />
      </svg>
    ),
    pin: (
      <svg width="15" height="26" viewBox="0 0 15 26" xmlns={ns}>
        <path d="M15 7.5s0 4.147-7.5 18.097C0 11.647 0 7.5 0 7.5 0 3.353 3.353 0 7.5 0S15 3.353 15 7.5zm-7.5 3.516c2.028 0 3.672-1.644 3.672-3.672 0-2.028-1.644-3.672-3.672-3.672-2.028 0-3.672 1.644-3.672 3.672 0 2.028 1.644 3.672 3.672 3.672z" fillRule="evenodd" />
      </svg>
    ),
    load: (
      <svg width="44" height="44" viewBox="0 0 44 44" xmlns={ns} stroke="#fff">
        <g fill="none" fillRule="evenodd" strokeWidth="2">
          <circle cx="22" cy="22" r="1">
            <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
            <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
          </circle>
          <circle cx="22" cy="22" r="1">
            <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
            <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
          </circle>
        </g>
      </svg>
    ),
    logo: (
      <svg width="260" height="172" viewBox="0 0 260 172" xmlns={ns}>
        <g fillRule="evenodd">
          <path d="M4.763 0v95.243h117.475V0H127v-23H0V0h4.763zM0-23h127v123H0V-23zm137.762 87.732v102.536h117.475V64.732H137.763zM133 60h127v112H133V60z" fillRule="nonzero" />
          <path d="M153.668 75.86l-2.287 6.45h4.64l-2.284-6.45h-.06zm4.978 13.797l-1.39-3.882h-7.09l-1.39 3.882H144L151.034 71h5.528l7.034 18.657h-4.95zm16.99-12.596c0-1.45-.838-2.26-2.662-2.26h-3.502v4.64h3.53c1.738 0 2.635-.75 2.635-2.37zm6.05 12.6h-5.21c-.52-.58-.752-1.11-.926-2.04l-.52-2.48c-.377-1.59-1.187-2.04-2.693-2.04h-2.865v6.567h-4.776V71h8.8c4.457 0 7.178 1.76 7.178 5.53 0 2.765-1.998 4.413-4.168 4.664v.056c1.736.28 2.75 1.2 3.212 2.988l.782 3.212c.232.894.58 1.564 1.187 2.207zm10.653 0h-4.9V74.91h-6.08V71h17.08v3.91h-6.11v14.747zm-37.81 3.69h4.37l2.78 12.54h.053l2.866-12.54h4.66L164.49 112h-4.95l-2.865-12.315h-.087L153.784 112h-5.008L144 93.343h4.863l2.865 12.54h.058l2.75-12.54zm22.892 4.86l-2.287 6.45h4.63l-2.285-6.45h-.06zM182.41 112l-1.39-3.882h-7.09L172.54 112h-4.776l7.034-18.657h5.528L187.36 112h-4.95zm6.05-18.657V112h13.43v-3.91h-8.567V93.343h-4.863zm15.11 0V112H217v-3.91h-8.568V93.343h-4.863zM59.694 58.623h-3.027v5.81H52v-18.57h7.694c4.473 0 7.112 2.307 7.112 6.393 0 4.03-2.89 6.365-7.112 6.365zM62 52.283c0-1.695-.86-2.723-2.86-2.723h-2.473v5.42h2.472c1.97 0 2.86-.943 2.86-2.694zm17.194-8.143V41H69.64v3.14h9.554zm-4.916 6.56l-2.195 6.42h4.445l-2.195-6.42h-.055zm4.778 13.73l-1.334-3.862h-6.805l-1.334 3.864H65l6.75-18.566h5.306l6.75 18.567h-4.75zm13.305 0h-4.69V49.757h-5.83v-3.89h16.39v3.89h-5.87V64.43zm19.62-20.29V41h-9.553v3.14h9.555zm-4.78 16.734c-2.22 0-3.11-1.556-3.11-3.975V45.86h-4.7v11.37c0 5.31 3.36 7.533 7.807 7.533 4.473 0 7.806-2.223 7.806-7.532V45.87h-4.666V56.9c0 2.42-.916 3.974-3.14 3.974zM83.67 86.666h-4.695V71.99h-5.833V68.1h16.39v3.89H83.67v14.676zm14.527-18.9c5.39 0 8.86 3.67 8.86 9.617 0 5.948-3.47 9.617-8.86 9.617-5.39 0-8.833-3.67-8.833-9.617 0-5.948 3.444-9.617 8.833-9.617zm0 3.89c-2.583 0-4 2.225-4 5.727 0 3.502 1.417 5.726 4 5.726 2.584 0 4.028-2.23 4.028-5.73s-1.444-5.73-4.028-5.73zm11.886-3.556v18.566h4.667V68.1h-4.667z" />
        </g>
      </svg>
    ),
    carat: (
      <svg width="14" height="8" viewBox="0 0 14 8" xmlns={ns}>
        <path d="M12.375 7.794L14 5.91 7.02 0 0 5.91l1.662 1.884 5.357-4.58" fillRule="evenodd" />
      </svg>
    ),
    phone: (
      <svg width="30" height="54" viewBox="0 0 30 54" xmlns={ns}>
        <path d="M28.668 9.148H1.332C.762 9.148.3 8.685.3 8.114V6.996C.3 3.138 3.45 0 7.324 0h15.352C26.55 0 29.7 3.138 29.7 6.996v1.118c0 .57-.462 1.034-1.032 1.034zM2.363 7.082h25.273v-.086c0-2.72-2.225-4.93-4.96-4.93H7.323c-2.735 0-4.96 2.21-4.96 4.93v.086zM22.676 54H7.324C3.45 54 .3 50.862.3 47.004v-4.427c0-.57.462-1.033 1.032-1.033h27.336c.57 0 1.032.463 1.032 1.033v4.427c0 3.858-3.15 6.996-7.024 6.996zM2.363 43.61v3.394c0 2.72 2.226 4.93 4.96 4.93h15.353c2.735 0 4.96-2.21 4.96-4.93V43.61H2.364z" />
        <circle cx="15" cy="47.539" r="1.972" />
        <path d="M19.902 4.574c0 .366-.297.663-.663.663h-8.48c-.368 0-.665-.297-.665-.663 0-.367.296-.664.662-.664h8.476c.365 0 .662.297.662.664zm8.766 39.036H1.332C.762 43.61.3 43.148.3 42.577V8.114c0-.57.462-1.033 1.032-1.033h27.336c.57 0 1.032.467 1.032 1.037V42.58c0 .57-.462 1.032-1.032 1.032zM2.363 41.544h25.273V9.148H2.363v32.396z" />
      </svg>
    ),
    warning: (
      <svg width="23" height="20" viewBox="0 0 23 20" xmlns={ns}>
        <path d="M12.52 15.92c0-.76-.62-1.38-1.4-1.38-.76 0-1.38.62-1.38 1.38 0 .76.62 1.4 1.38 1.4.78 0 1.4-.64 1.4-1.4zm0-7.74V5.26H9.74v2.92c0 .64.16 1 .26 1.62l.52 3.24h1.18l.54-3.24c.12-.62.28-.98.28-1.62zm9.74 10.22c0 1.1-.92 1.6-1.9 1.6H1.9c-1 0-1.9-.52-1.9-1.6 0-.42.14-.84.36-1.22L9.58 1.1c.34-.56.86-1.1 1.56-1.1.68 0 1.2.54 1.52 1.1l9.24 16.1c.2.36.36.78.36 1.2z" fillRule="evenodd" />
      </svg>
    ),
  };

  return icons[icon] || null;
}
