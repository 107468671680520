import React from 'react';
import theme from '../theme';
import PatternedCircle from 'src/byod/images/patterned-circle.svg';

export default function Loader(props) {
  const {
    color: {
      primary
    }
  } = theme;
  return (
    <div className="loader-container">
      <div className="loader-inner flex flex-jc-center flex-column">
        <div className="loader-image">
          <PatternedCircle color={primary} />
        </div>
        {props.text && (<p className="loader-text" dangerouslySetInnerHTML={{ __html: props.text }} />)}
      </div>
    </div>
  );
}
